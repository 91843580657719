<template>
  <layout>
    <template slot="header">
      Notifications settings
    </template>

    <template>
      <v-form>
        <form-subheader title="General" />
        <v-radio-group v-model="general[0].value" class="ma-0">
          <form-radio
            icon="mdi-volume-high"
            title="All notifications"
            value="all"
          />
          <form-radio
            icon="mdi-star"
            title="Favourites only"
            value="favouritesOnly"
          />
          <form-radio icon="mdi-volume-off" value="muteAll" title="Mute all" />
        </v-radio-group>
        <form-subheader title="Optional tags" />
        <v-combobox
          v-for="item in tags"
          :key="item.id"
          v-model="item.value"
          :label="item.title"
          multiple
          chips
        />
        <form-subheader title="Options" />
        <form-switcher
          v-for="option in options"
          :key="option.title"
          v-model="option.value"
          :title="option.title"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="close">
        Cancel
      </v-btn>
      <v-btn text color="primary" depressed :loading="isLoading" @click="save">
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { ref, onMounted, reactive, toRefs } from '@vue/composition-api';
import { useLazyQuery, useMutation, useResult } from '@vue/apollo-composable';

import { useAuth } from '@/modules/auth/api';
import schema from '@/modules/account/schema.graphql';

import Layout from '@/components/popup/PopupLayoutDefault';

export default {
  name: 'NotificationSettings',
  components: {
    Layout
  },
  setup(props, { emit }) {
    const showOtherNoty = ref(false);
    const emailNoty = ref(false);
    const isLoading = ref(false);
    const smsNoty = ref(false);
    const snackbar = ref(false);
    const state = reactive({
      tags: [
        {
          id: 'Include tags',
          title: 'Include tags',
          key: 'alertsIncludeTags',
          value: []
        },
        {
          id: 'Exclude tags',
          title: 'Exclude tags',
          key: 'alertsExcludeTags',
          value: []
        }
      ],
      options: [
        {
          id: '9',
          title: 'E-mail notifications',
          key: 'notificationsEmailNotifications',
          value: false
        },
        {
          id: '10',
          key: 'notificationsSmsNotifications',
          title: 'SMS notifications',
          value: false
        },
        {
          id: '11',
          key: 'notificationsWhatsappNotifications',
          title: 'WhatsApp notifications',
          value: false
        },
        {
          id: '12',
          key: 'notificationsSoundInTheApp',
          title: 'Sound in the app',
          value: false
        }
      ],
      general: [
        {
          id: '11',
          key: 'notificationsGeneral',
          value: 'all'
        }
      ]
    });

    const { getUserProfileId } = new useAuth();

    const { mutate: changeProperties } = useMutation(
      schema.updateObjectProperties
    );

    const lazyQuery = useLazyQuery(
      schema.loadProfile,
      {
        id: null
      },
      {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
      }
    );

    const notificationSettings = useResult(lazyQuery.result, null, data => {
      return data.object.objectProperties;
    });

    lazyQuery.onResult(() => {
      const setState = arr => {
        arr.forEach(item => {
          let fromObject = notificationSettings.value.find(
            itemFromObject => itemFromObject.key === item.key
          );

          if (fromObject && fromObject.key === item.key) {
            item.id = fromObject.id;
            item.value = fromObject.value;
          }
          if (!fromObject) {
            item.id = false;
          }
        });
      };

      setState(state.tags);
      setState(state.options);
      setState(state.general);
    });

    function close() {
      emit('close');
    }

    const save = () => {
      let data = [...state.tags, ...state.options, ...state.general].map(
        item => ({
          value: item.value,
          propertyId: item.id
        })
      );

      changeProperties({
        input: {
          propertiesArray: data
        }
      }).then(() => {
        close();
      });
    };

    onMounted(async () => {
      lazyQuery.variables.value.id = await getUserProfileId();
      await lazyQuery.load();
    });
    return {
      ...toRefs(state),
      close,
      save,
      showOtherNoty,
      emailNoty,
      smsNoty,
      isLoading,
      snackbar
    };
  }
};
</script>

<style lang="scss">
.theme--light.custom-label .v-label {
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.custom-label .v-label {
  color: #ffffff;
}

.cursor {
  cursor: pointer;
}
</style>
